<template>
  <div id="services">
    <!-- <div
      class="ma-auto container"
      style="text-align: center;"
    >
      <h2 style="color: #700000">Services</h2>
      <h1
        style="color: white"
        class="mb-5"
      >What We Offer</h1>
      <v-row class="ma-auto">
        <v-col
          class="ma-auto"
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          style="text-align: center;"
          v-for="service in services"
          :key="service.title"
        >
          <div data-aos="zoom-out-down">
            <v-avatar
              class="ma-auto"
              color="white"
              size="100"
            >
              <v-icon color="#810606">{{service.img}}</v-icon>
            </v-avatar>
            <h2
              class="mt-2"
              style="color: black"
            >{{service.title}}</h2>
            <p style="color: white">{{service.desription}}</p>
          </div>
        </v-col>
      </v-row>
    </div> -->

    <v-img
      alt="programming"
      src="@/assets/design.png"
      id="services"
    >
      <v-row
        class="ma-auto"
        style="max-width:1400px"
      >
        <v-col
          class="ma-auto"
          cols="12"
          lg="4"
          md="6"
          sm="12"
          xs="12"
          style="text-align: center;"
          v-for="service in services"
          :key="service.title"
        >

          <v-sheet
            data-aos="zoom-out-down"
            elevation="10"
            class="rounded-circle mx-auto"
            width="100"
            height="100"
          >

            <v-container
              fill-height
              fluid
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-icon color="primary">{{service.img}}</v-icon>
              </v-row>
            </v-container>

          </v-sheet>

          <h3
            data-aos="zoom-out-down"
            class="mt-2 mx-auto"
            style="color: #858585 "
          >{{service.title}}</h3>
          <p style="color: #858585">{{service.desription}}</p>

        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
export default {
  data: () => ({
    services: [
      {
        img: "mdi-tools",
        title: "Machine Repair",
        desription: "Phones | Macs | Laptops | Desktop | All-in-Ones",
        isWhite: false,
      },
      {
        img: "mdi-message",
        title: "Quotation Procedure",
        desription: "Quotation provided upon inspection.",
        isWhite: true,
      },
      {
        img: "mdi-database-remove",
        title: "Data Recovery and Virus Removal",
        desription: "Recovery of lost data due to virus or corruption",
        isWhite: true,
      },
      {
        img: "mdi-desktop-classic",
        title: "Build your Custom System",
        desription: "Gaming PC | Office PC | Servers",
        isWhite: true,
      },
      {
        img: "mdi-download",
        title: "Hardware and Software Installation",
        desription: "Any, and all upgrades to your system",
        isWhite: true,
      },
      {
        img: "mdi-file-chart",
        title: "Insurance Report",
        desription: "Delivery of clear and concise insurance reports.",
        isWhite: false,
      },
    ],
  }),
};
</script>

<style scoped>
@media only screen and (min-width: 999px) {
  .container {
    width: 1000px;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
}
</style>