<template>
  <!-- <div
    id="information"
    style="padding: 100px 0px"
  >
    <v-row class="ma-auto container">
      <v-col
        class="ma-auto"
        cols="12"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        style="text-align: center"
        v-for="service in services"
        :key="service.title"
      >
        <div
          data-aos="zoom-in"
          :data-aos-duration="service.duration"
          v-if="service.href"
        >
          <a
            :href="service.href"
            target="_blank"
          >
            <v-avatar
              class="ma-auto"
              color="white"
              size="100"
            >
              <v-icon color="#810606">{{ service.img }}</v-icon>
            </v-avatar>
            <h2
              class="mt-2"
              style="color: black"
            >{{ service.title }}</h2>
            <p style="color: white; margin-bottom:0px">{{ service.desription }}</p>
            <p style="color: white">{{ service.description2}}</p>
          </a>
        </div>
        <div
          data-aos="zoom-in"
          :data-aos-duration="service.duration"
          v-else
        >
          <div
            data-aos="zoom-in"
            v-if="service.title == 'Email'"
            @click="scrollTo"
            :style="{ cursor: 'pointer' }"
          >
            <v-avatar
              class="ma-auto"
              color="white"
              size="100"
            >
              <v-icon color="#810606">{{ service.img }}</v-icon>
            </v-avatar>
            <h2
              class="mt-2"
              style="color: black"
            >{{ service.title }}</h2>
            <p style="color: white">{{ service.desription }}</p>
          </div>
          <div
            data-aos="zoom-in"
            :data-aos-duration="service.duration"
            v-else
          >
            <v-avatar
              class="ma-auto"
              color="white"
              size="100"
            >
              <v-icon color="#810606">{{ service.img }}</v-icon>
            </v-avatar>
            <h2
              class="mt-2"
              style="color: black"
            >{{ service.title }}</h2>
            <p style="color: white; margin-bottom:0px">{{ service.desription }}</p>
            <p style="color: white">{{ service.description2}}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div> -->
  <div
    id="information"
    style="margin-bottom: 200px;"
  >
    <v-img
      src=""
      class="mx-auto"
    >
      <div
        style="max-width:1400px;"
        class="mx-auto"
      >
        <v-row
          style="padding-top:20px;"
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-row>
              <v-col
                v-for="(service, i) in services"
                :key="i"
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    style="max-height: 130px"
                  >

                    <v-card
                      v-if="!$vuetify.breakpoint.mobile"
                      @click="clickSheet(service)"
                      :data-aos="$vuetify.breakpoint.mobile ? 'fade-up' : 'fade-right'"
                      data-aos-duration="1000"
                      class="mx-auto "
                      max-width="500"
                      elevation="20"
                      height="90"
                      style="border-radius: 70px;"
                    >
                      <v-container fill-height>
                        <v-row
                          align="center"
                          justify="center"
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>{{ service.img}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ service.title}}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{service.description}}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                          </v-list-item>
                        </v-row>
                      </v-container>

                    </v-card>
                    <div
                      v-else
                      :data-aos="$vuetify.breakpoint.mobile ? 'fade-up' : 'fade-right'"
                      data-aos-duration="1000"
                      class="mx-auto"
                      v-ripple
                      style="border-radius: 100px; width: 100px;height: 100px;"
                    >
                      <v-sheet
                        @click="clickSheet(service)"
                        class="rounded-circle"
                        width="100"
                        height="100"
                        elevation="5"
                      >
                        <v-container
                          fill-height
                          fluid
                        >
                          <v-row
                            align="center"
                            justify="center"
                          >

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="hover"
                                  :class="{'active': (active === i), 'inactive': (active === -1)}"
                                  @mouseover="toggleActive(i)"
                                  @mouseleave="toggleActive(-1)"
                                  color="primary"
                                >{{service.img}}</v-icon>
                              </template>
                              <span>{{ service.description }}</span>
                            </v-tooltip>

                          </v-row>
                        </v-container>
                      </v-sheet>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-card
                      v-if="$vuetify.breakpoint.mobile"
                      class="mx-auto text-center"
                      elevation="0"
                      max-width="200"
                      color="transparent"
                    >
                      <v-card-text>
                        {{ service.description }}
                      </v-card-text>
                    </v-card>

                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <contact
              :data-aos="$vuetify.breakpoint.mobile ? 'fade-up' : 'fade-left'"
              data-aos-duration="1000"
            />
          </v-col>
        </v-row>
      </div>
    </v-img>
  </div>
</template>
<script>
import Contact from "@/components/Contact.vue";
export default {
  components: {
    Contact,
  },
  methods: {
    scrollTo() {
      window.scrollTo(0, document.getElementById("contact").offsetTop);
    },
    toggleActive(value) {
      this.active = value;
    },
    clickSheet(service) {
      if (service.title.includes("Store")) {
        let url = service.href;
        window.open(url, "_blank").focus();
      } else if (service.title.includes("Email")) {
        window.location.href = "mailto:" + service.description;
        // let email = service.description;
        // let dummy = document.createElement("textarea");
        // document.body.appendChild(dummy);
        // dummy.value = email;
        // dummy.select();
        // document.execCommand("copy");
        // document.body.removeChild(dummy);
      } else if (service.title.includes("Call")) {
        window.open("tel:" + service.description, "_self");
      }
    },
  },
  data: () => ({
    active: "",
    services: [
      {
        duration: 800,
        img: "mdi-map-marker",
        title: "Store Address",
        description:
          "Canit Limited, 295 Blenheim Road, Upper Riccarton, Christchurch",
        href: "https://www.google.co.nz/maps/place/295+Blenheim+Road,+Upper+Riccarton,+Christchurch+8041/@-43.5388297,172.5810391,17z/data=!3m1!4b1!4m5!3m4!1s0x6d318abd658683a9:0xc8683a54ed5caa37!8m2!3d-43.5388336!4d172.5832278",
      },
      {
        duration: 1500,
        img: "mdi-email",
        title: "Email",
        description: "sales@canit.co.nz",
      },
      {
        duration: 2000,
        img: "mdi-phone",
        title: "Call Us",
        description: "034208188",
        href: "tel:03-420-8188",
      },
      {
        duration: 2500,
        img: "mdi-clock-time-nine",
        title: "Opening Hours",
        description: "Monday - Friday: 10am - 6pm ",
        description2: "Saturday - Sunday: Closed",
        href: "#information",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 999px) {
  .container {
    width: 1000px;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
}

.hr-style {
  width: 100px;
  border: 1px solid #520000;
}

.hover {
  transition: all 0.8;
  &.active {
    transform: scale(2) !important;
  }
  &.inactive {
    transform: scale(1) !important;
  }
}
</style>