<template>
  <div>

    <div
      id="software"
      style="width: 100%; height: 100vh; position: relative;margin-top: 150px;"
      overlay="linear-gradient(45deg,#61000030,#6363636b)"
    >
      <video-background
        :src="video"
        style="width: 100%; height: 70%"
      >
        <h1 style="
            position: absolute;
            left: 50%;
            top: 50%;
            color: white;
            transform: translate(-50%, -50%);
            text-align: center;
          ">
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            :style="{fontSize: !$vuetify.breakpoint.mobile ? '50px' : '17px'}"
          >
            Now expanding to <br />
            Software Development
          </div>
        </h1>
      </video-background>
    </div>
  </div>
</template>

<script>
import video from "../assets/coding.mp4";
export default {
  data: () => ({
    video: video,
  }),
};
</script>
