import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#590003',
        secondary: '#4d4d4d',
        anchor: '#8c9eff',
      },
    },
  },
})

export default vuetify
