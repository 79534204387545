<template>
  <div
    id="home"
    :style="{
      backgroundColor: '#eee',
      width: '100%',
      height: '100vh',
      position: 'relative',
    }"
  >
    <v-img
      alt="computer"
      class="ma-auto"
      style="width: 100%; height: 100%"
      :src="require('../assets/background.jpeg')"
    ></v-img>
    <div style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      ">
      <div
        data-aos="fade-in"
        data-aos-duration="3000"
      >
        <v-img
          alt="canit limited"
          class="ma-auto logo"
          :src="require('../assets/canitLogo.png')"
          :width="$vuetify.breakpoint.mobile ? 200 : 350"
        ></v-img>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h1
          class="mt-4 "
          :style="style"
        >IT Hardware Specialist</h1>
        <h2 :style="{color: '#700000', fontSize: style.fontSize}">Yes, We Can!</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    style() {
      return {
        fontSize: this.$vuetify.breakpoint.mobile ? "19px" : "30px",
      };
    },
  },
};
</script>


<style scoped>
</style>