export default {
    state: {
        navigation: false,
        page: ''
    },
    mutations: {
        toggleNaviation (state) {
            state.navigation = !state.navigation;
        },
        closeNavigation (state) {
            state.navigation = false;
        }
    },
    actions: {
    },
    getters: {
        navigation (state) {
            return state.navigation;
        }
    }
}