import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store/index';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import AOS from 'aos';
import VueSmoothScroll from 'vue2-smooth-scroll';
import 'aos/dist/aos.css';
import { Plugin } from 'vue-responsive-video-background-player';

Vue.use(Plugin);
Vue.use(VueSmoothScroll, { duration: 200 });
Vue.use(VueReCaptcha, {
	siteKey: '6LfvHP0UAAAAAALiryB1HzF43oSrK0HHvCCcf0sv',
	autoHideBadge: false,
});
Vue.config.productionTip = false;

new Vue({
	created() {
		AOS.init();
	},
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');
