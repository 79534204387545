<template>
  <v-app>
    <v-main>
      <desktop-navbar></desktop-navbar>
      <Home />
      <About />
      <Services />
      <Software />
      <Information />
      <!-- <Contact /> -->
      <Footer />
      <mobile-navbar></mobile-navbar>
    </v-main>
  </v-app>
</template>

<script>
import DesktopNavbar from '@/components/DesktopNavbar.vue';
import MobileNavbar from '@/components/MobileNavbar.vue';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Software from './components/Software';
import Information from './components/Information';
// import Contact from './components/Contact';
import Footer from './components/Footer';



export default {
  name: 'App',

  components: {
    MobileNavbar,
    DesktopNavbar,
    Home,
    About,
    Services,
    Software,
    Information,
    // Contact,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
