<template>
  <div>
    <v-app-bar
      v-if="!$vuetify.breakpoint.mobile"
      dark
      fixed
      color="primary"
      inverted-scroll
      height="100"
    >

      <v-spacer></v-spacer>
      <a href="#home">
        <v-btn
          text
          icon
        >
          Home
        </v-btn>
      </a>

      <v-spacer></v-spacer>
      <a
        href="#about"
        v-smooth-scroll="{offset: -130 }"
      >
        <v-btn
          text
          icon
        >
          About
        </v-btn>
      </a>
      <v-spacer></v-spacer>
      <a
        href="#services"
        v-smooth-scroll="{offset: -180 }"
      >
        <v-btn
          text
          icon
        >
          Services
        </v-btn>
      </a>
      <v-spacer></v-spacer>
      <a
        href="#software"
        v-smooth-scroll="{offset: -100 }"
      >
        <v-btn
          text
          icon
        >
          Software
        </v-btn>
      </a>
      <v-spacer></v-spacer>
      <a
        href="#information"
        v-smooth-scroll="{offset: -150 }"
      >
        <v-btn
          text
          icon
        >
          Information
        </v-btn>
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>