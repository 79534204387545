<template>
  <div>
    <section class="map">
      <iframe
        width="100%"
        height="500px"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.232049157391!2d172.58065051531895!3d-43.539204579125304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318b3b3555d0ed%3A0x8b795e28ded80f6e!2sCanit%20Limited!5e0!3m2!1sen!2snz!4v1590622661146!5m2!1sen!2snz"
      ></iframe>
      <br />
      <small>
        <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.232049157391!2d172.58065051531895!3d-43.539204579125304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318b3b3555d0ed%3A0x8b795e28ded80f6e!2sCanit%20Limited!5e0!3m2!1sen!2snz!4v1590622661146!5m2!1sen!2snz"></a>
      </small>
    </section>
    <div
      :class="$vuetify.breakpoint.mobile? 'mb-10' :''"
      style="width: 100%; height: 90px;padding: 20px; background-color: #373637;"
    >
      <p style="text-align:center; color: #888;">{{this.date}}</p>
    </div>
  </div>
</template>

<script>

export default {
  mounted () {
    this.getDate();
  },
  data: () => ({
    date: '2020'
  }),
  methods: {
    getDate () {
      let d = new Date();
      let year = d.getFullYear();

      this.date = `Copyright © CanIT Limited. ${year}`;
    }
  }
}
</script>
