<template>
  <!-- <div id="about" style="text-align: center; width: 100%; padding: 200px 20px">
    <div data-aos="fade-up" data-aos-duration="1000" class="ma-auto container">
      <h1 class="mb-5" style="color: #700000">About Us</h1>
      <p>
        CanIT Limited is one of Canterbury's fastest growing IT solutions
        providers for personal, small and medium businesses. Focussing on laptop
        repair and maintenance, computer parts sales and IT systems consulting.
        CanIT Limited Provides quality and cost effective services.
      </p>
    </div>
  </div> -->
  <div id="about">
    <v-container style="margin-top: 100px; margin-bottom: 100px;">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >

          <v-img
            alt="Canit Limited - Computer Phone Repair"
            :data-aos="!$vuetify.breakpoint.mobile ? 'fade-right' : 'fade-up'"
            data-aos-duration="1000"
            class="mx-auto"
            src="@/assets/about.png"
            width="1000"
          ></v-img>

        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div
            :data-aos="!$vuetify.breakpoint.mobile ? 'fade-right' : 'fade-up'"
            data-aos-duration="1000"
            class=" mx-auto display-2"
          >
            About Us
          </div>
          <hr class="hr-style">

          <v-card
            :data-aos="!$vuetify.breakpoint.mobile ? 'fade-left' : 'fade-up'"
            data-aos-duration="1000"
            elevation="0"
            class="mx-auto mt-6 bigger-font"
          >
            CanIT Limited is one of Canterbury's fastest growing IT solutions providers for personal, small and medium businesses. Focussing on laptop repair and maintenance, computer parts sales and IT systems consulting. CanIT Limited Provides quality and cost effective services.
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.hr-style {
  width: 100px;
  border: 1px solid #520000;
}
.bigger-font {
  font-size: 20px;
}
/* @media only screen and (min-width: 999px) {
  .container {
    width: 1000px;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
} */
</style>