<template>
  <div>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.mobile"
      v-model="value"
      background-color="primary"
      dark
      shift
      fixed
      class="pl-10 pr-10"
    >

      <v-btn @click="routeTo('#home')">
        <span>Home</span>

        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn @click="routeTo('#about')">
        <span>About</span>

        <v-icon>mdi-alpha-a-box</v-icon>
      </v-btn>

      <v-btn @click="routeTo('#services')">
        <span>Services</span>

        <v-icon>mdi-tools</v-icon>
      </v-btn>

      <v-btn @click="routeTo('#software')">
        <span>Software</span>

        <v-icon>mdi-laptop</v-icon>
      </v-btn>
      <v-btn @click="routeTo('#information')">
        <span>Info</span>

        <v-icon>mdi-information</v-icon>
      </v-btn>

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  data: () => ({ value: 1 }),
  methods: {
    routeTo (id) {
      document.location.href = id
    }
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
</style>